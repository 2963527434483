const achievementUserData = {
    "setolo": {
        nickname: "Setolo",
        titles: ["The Great Setolo", "Blind", "Lost"]
    },
    "bawnzee": {
        hoursWatched: 211,
        pointsEarned: 92000,
        pointsSpent: 26000,
        chatsSent: 3300,
        nickname: "Bawnzee",
        titles: ["Almighty Bawnzee", "Spawnzor"]
    },
    "amoebavi": {
        hoursWatched: 32,
        pointsEarned: 6000,
        pointsSpend: 2000,
        chatsSent: 600,
        nickname: "AmoebaVI",
        titles: ["Single-celled"]
    },
    "electricaxel": {
        hoursWatched: 190,
        pointsEarned: 72000,
        pointsSpent: 20000,
        chatsSent: 10400,
        nickname: "ElectricAxel",
        titles: ["Axel of Doom", "Overlord"]
    },
    "expoint": {
        hoursWatched: 81,
        pointsEarned: 22000,
        pointsSpent: 6200,
        chatsSent: 249,
        nickname: "Expoint",
        titles: ["Exy the Sexy", "Hackerman"]
    },
    "maikachan28": {
        hoursWatched: 156,
        pointsEarned: 69658,
        pointsSpent: 12840,
        chatsSent: 2194,
        nickname: "Maikachan",
        titles: ["Maika of Doom", "Overlord"]
    },
    "zerostereo": {
        nickname: "ZeroStereo",
        titles: ["Bike Enthusiast", "Bad at j---"]
    },
    "anthonyd4x": {
        hoursWatched: 2,
        pointsEarned: 2500,
        nickname: "AnthonyD4X",
        titles: ["Lurker"]
    },
    "pietrovitale7": {
        hoursWatched: 2,
        pointsEarned: 2500,
        nickname: "PietroVitale",
        titles: ["Zoe Main"]
    },
    "conmiro": {
        hoursWatched: 2,
        pointsEarned: 2500,
        nickname: "Conmiro",
        titles: ["Bit Abuser", "Lurker"]
    },
    "cat12567": {
        hoursWatched: 28,
        pointsEarned: 8500,
        nickname: "cat",
        titles: ["Bit Lord"]
    },
    "henrytouchdown": {
        hoursWatched: 2,
        pointsEarned: 2500,
        nickname: "henrytouchdown",
        titles: ["Shadow Raider"]
    },
    "randompl0x": {
        hoursWatched: 2,
        pointsEarned: 2500,
        nickname: "RandomPl0x",
        titles: ["Pl0x", "Surprise Visitor", "CM"]
    },
    "jadehex": {
        nickname: "JadeHex",
        titles: ["CM"]
    },
    "dkayed": {
        nickname: "Dkayed",
        titles: ["Dkayed"]
    },
    "synnthara": {
        nickname: "Synnthara",
        titles: ["Synnthara"]
    },
    "itsbradazhd": {
        nickname: "ItsBradazHD",
        titles: ["CM"]
    },
    "yazan": {
        nickname: "Yazan",
        titles: ["Gone But Not Forgotten"]
    },
    "killerkel": {
        hoursWatched: 8,
        pointsEarned: 2000,
        nickname: "Killerkel",
        titles: ["Nihongo", "Surprise Visitor", "Stalker"]
    }
}

const timeAchievements = [
    { name: "Blind Crew Member", requirement: 1, description: "Welcome to the Blind Crew! (1 hour watch-time)" },
    { name: "Blind Crew Regular", requirement: 6, description: "You've seen me hit at least one spike (6 hour watch-time)" }, 
    { name: "Blind Crew Veteran", requirement: 12, description: "You've probably seen me lost and blind a few times, huh? (12 hour watch-time)" }, 
    { name: "Spike Lover", requirement: 24, description: "I think the spikes are growing on you too! (1 day watch-time)" }, 
    { name: "Spike Magnet", requirement: 48, description: "At least I'm not the only one. (2 day watch-time)" }, 
    { name: "Spiketastic", requirement: 72, description: "Your love for spikes matches mine! (3 day watch-time)" }, 
    { name: "Forever Blind", requirement: 100, description: "We're both blind now! (100 hour watch-time)" }, 
    { name: "Time Lord", requirement: 150, description: "I can almost see in-between dimensions. Just not infront of me. (150 hour watch-time)" }
];
const pointsEarnedAchievements = [
    { name: "Point Earner", requirement: 500, description: "Maybe a bottle of water would be good? (Earn 500 points)" }, 
    { name: "Point Enthusiast", requirement: 2000, description: "Lots of unpretzeling (Earn 1,000 points)"  }, 
    { name: "Point Monopoly", requirement: 10000, description: "That's a lot of banned actions... (Earn 10,000 points)" }, 
    { name: "Power Point", requirement: 20000, description: "I'm a bit scared now. (Earn 20,000 points)" }, 
    { name: "Appointed", requirement: 35000, description: "You are now king of points. (Earn 35,000 points)" },
    { name: "O(n!) Point", requirement : 50000, description: "Impossible. No one could possibly make this much. (Earn 50,000 points)" }, 
    { name: "NULL Pointer", requirement: 65000, description: "(ERROR: 65000 is above the valid range)" }
];

const subscriberAchievements = [
    { name: "VIP", requirement: 3, description: "You've been subscribed for a while! (3 months subscribed)" },
    { name: "Half Blind", requirement: 6, description: "! (3 months subscribed)" },
]

const chatsSentAchievements = ["Chatter", "Friendly"];

export const totalAchievements = pointsEarnedAchievements.length + timeAchievements.length;

// Gets achievement information by Twitch username using the above object
export const getAchievements = (input) => {
    const username = input?.toLowerCase();
    if (!username || typeof username !== "string") return {};

    const achievements = {
        normal: [],
        titles: []
    };

    const userAchievements = achievementUserData[username];

    if (!userAchievements) return {};
    
    const userWatchtimeAchievements = timeAchievements.filter((item) => item.requirement <= (userAchievements?.hoursWatched || 0));
    const userPointsEarnedAchievements = pointsEarnedAchievements.filter((item) => item.requirement <= (userAchievements?.pointsEarned || 0));

    achievements.normal.push(...userWatchtimeAchievements, ...userPointsEarnedAchievements);
    achievements.titles.push(...userAchievements.titles);

    return achievements;
}