import React, { useRef, useEffect, useState } from 'react';
import "../styles/GameRush.css";
import Tippy from '@tippyjs/react';
import { getAchievements, totalAchievements } from '../config/achievements.js';

export const GameRush = () => {
    const playingGameId = 22;
    const [games, setGames] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [selectedGameId, setSelectedGameId] = useState(playingGameId);
    const [canSelect, setCanSelect] = useState(false);
    const [infoExpanded, setInfoExpanded] = useState(false);
    const [userAchievements, setUserAchievements] = useState({});
    const sliderRef = useRef(null);
    const gameElementRefs = useRef([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const getGames = async () => {
            const gameDataResponse = await fetch("/api/steam-games?rush=true", { signal });
            const gameData = await gameDataResponse.json();

            if (gameDataResponse) {
                setGames(gameData);
            }
        }

        getGames();

        return () => {
            if (signal && !signal.aborted) controller.abort();
        }
    }, [])

    // useEffect(() => {
    //     const scroll = setTimeout(() => {
    //         scrollIntoView(playingGameId);
    //     }, 100)

    //     return () => {
    //         clearTimeout(scroll)
    //     }
    // }, [games, playingGameId])
    
    useEffect(() => {
        if (!sliderRef && !sliderRef.current) return;
        const slider = sliderRef.current;
        slider.scrollLeft = slider.scrollWidth - slider.clientWidth;
        
    }, [games, playingGameId])

    useEffect(() => {
        if (!isDragging) {
            setCanSelect(false);
        }
    }, [isDragging])

    const startDragging = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const scrollIntoView = (id) => {
        if (!gameElementRefs && !gameElementRefs.current) return;
        const node = gameElementRefs.current[id];
        if (!node) return;

        node.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center"
        })
    }

    const stopDragging = (e) => {
        setIsDragging(false);
        if (Math.abs((e.pageX - sliderRef.current.offsetLeft) - startX) < 6) {
            setCanSelect(true);
        };
    };

    const onDrag = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    const onClickGame = (index) => {
        setSelectedGameId(index);
        scrollIntoView(index);
    }

    const getOrdinalSuffix = (number) => {
        const lastTwoDigits = number % 100;
        if (lastTwoDigits > 10 && lastTwoDigits < 14) {
            return `${number}th`;
        }

        switch (number % 10) {
            case 1:
            return `${number}st`;
            case 2:
            return `${number}nd`;
            case 3:
            return `${number}rd`;
            default:
            return `${number}th`;
        }
    }

    const renderAchievements = () => {
        if (!userAchievements || !userAchievements.normal || !userAchievements.titles) return;
        return (
            <>
                Achievements: 
                <div className="grid grid-cols-4 py-4 gap-2">
                    {userAchievements.normal.map((item, index) => 
                        (
                            <Tippy content={item.description}>
                                <div className="select-none transition-colors hover:bg-gray-700 text-amber-300 p-4 bg-gray-900 rounded-sm border-2 border-slate-950 text-center" key={index}>
                                    {item.name}
                                </div>
                            </Tippy>
                        )
                    )}
                </div>
                <div>Total Achievements: {userAchievements.normal.length}/{totalAchievements}</div>
                <div>Titles: {userAchievements.titles.join(", ")}</div>
            </>
        )
    }

    const onInputChanged = (e) => {
        const input = e.target.value || "";
        setUserAchievements(getAchievements(input.trim()));
    }

    return (
        <div>
            <h1>Game Rush!</h1>
            <p className="text-gray-500 text-sm">Check out games I'm playing in the Game Rush event.</p>
            <div className="flex flex-col items-center bg-gray-950 bg-opacity-75 p-4 border border-gray-600 rounded-md my-4 text-gray-300 box-shadow-sm">
                <h1 className="text-2xl lg:text-3xl font-bold">The End</h1>
                <div className="game-rush-info flex flex-col text-sm lg:text-lg gap-6 p-4 w-full max-w-[1000px] bg-gray-900 bg-opacity-75 my-4">
                    <div>
                        <span className="text-amber-400">Monday, December 30th, 2024</span> marks the end of Game Rush. I had so much fun this year, it was a great time. We beat a total of 22 games, and I enjoyed every one of them from start to finish. Thank you all for joining me on this experience and supporting me.
                    </div>
                    <div>
                        If you joined me for the ride, thank you. Now it's time for my next journey, I'm not sure where that will lead me, but I'm going to try my best until I figure all that out. Until next time, from now until forever, SetoloBlind.
                    </div>
                    <div>
                        This was the <span className="text-green-300">Good Ending</span>.
                    </div>
                    <div>
                        Type in your Twitch username below to see if you have any achievements!
                        <div>
                            Enter username: <input className="bg-gray-800 mt-2" onChange={onInputChanged} />
                        </div>

                        <div className="bg-gray-700 p-4 my-2">
                            {renderAchievements()}
                        </div>
                    </div>
                </div>

            </div>
            
            <button onClick={() => setInfoExpanded(prev => !prev)} className={`game-rush-info-container w-full flex flex-col items-center bg-gray-950 bg-opacity-75 p-4 border border-gray-600 rounded-md my-4 text-gray-300 box-shadow-sm ${infoExpanded ? "info-expanded" : ""}`}>
                <h1 className="text-2xl lg:text-3xl font-bold">What is <span className="text-rose-400">Game Rush</span>?</h1>
                {infoExpanded ? 
                    <div className="game-rush-info text-left flex flex-col text-sm lg:text-lg gap-6 p-4 w-full max-w-[1000px] bg-gray-900 bg-opacity-75 my-4">
                        <div>From March onwards, <span className="text-green-300">Setolo</span> is going to be playing games from the list below.
                            <span className="text-amber-400"> 2 hours a day, Mondays, Wednesdays, and Fridays at 8:00 PM EST</span>, until he finishes all of them! Only on Twitch: <a target="_blank" rel="noreferrer" className="text-blue-400 hover:underline" href="https://www.twitch.tv/setolo/">https://www.twitch.tv/setolo/</a><br/>You can click on each game below to see information about it, and see how close he is to playing it.
                        </div>
                        <div>
                            Here's a few rules about Game Rush:
                            <ul className="list-decimal marker:text-blue-400 w-full lg:w-3/4 bg-zinc-900 py-4 bg-opacity-75 rounded-md mt-1 pr-2">
                                <li>When a game is considered beaten, Setolo will move to the next game in the list, in order.</li>
                                <li>After reaching the main credits for the game, the game will be considered complete. Extra endings will not be played, unless it's to reach the main ending of the game.</li>
                                <li>Until the currently selected game is either beaten or abandoned, no other games will be played.</li>
                                <li>Game Rush will end at the end of the year, regardless of whether or not all games were completed.</li>
                            </ul>
                        </div>
                        <div>
                            <span className="text-green-300">Setolo</span> started this event because of a long list of games he never got back to. Now, he wants to beat as many games as he can within the year! Feel free to drop any suggestions for games on stream. Given the length and type of the game, it might get added to the list!
                        </div>
                    </div>
                : 
                    <div>Click to expand</div>
                }

            </button>
            <div className="flex justify-content-center w-full h-52 bg-zinc-950 bg-opacity-95 box-shadow-sm mb-4">
                <img alt="Setolo's Game Rush" className="pointer-events-none object-contain ml-auto mr-auto" src={"/images/setolo-game-rush-logo.png"} />
            </div>
            <div
                className="flex games-played p-4 gap-4 select-none overflow-x-scroll bg-zinc-950 bg-opacity-50"
                ref={sliderRef}
                onMouseDown={startDragging}
                onMouseLeave={stopDragging}
                onMouseUp={stopDragging}
                onMouseMove={onDrag}
            >
                {games.length === 0 && !games[0]?.header_image && (
                    [...Array(6)].map((e,i) => (
                    <div key={i} className="flex-none">
                        <div className={`relative w-[460px] h-[215px] box-shadow-sm border border-gray-800 brightness-[0.3]`}></div>
                    </div>
                ))
                )}
                {games.map((game, index) => (
                    <button onClick={() => canSelect && onClickGame(index)} ref={(e) => gameElementRefs.current[index] = e} key={index} className="flex-none">
                        <div className={`relative w-64 h-full lg:w-[460px] box-shadow-sm border border-gray-800 hover:scale-105 transition duration-300 ${(index === playingGameId || index === selectedGameId) ? "" : "brightness-[0.3] hover:brightness-100"}`}>
                            <div className="absolute top-1 left-1 p-1 text-md z-10 text-outline font-black w-9 bg-zinc-950 bg-opacity-85 text-center">{index + 1}</div>
                            {index === playingGameId && (
                                <div>
                                    <div className="absolute border-4 border-yellow-500 w-full h-full"></div>
                                    <div className="absolute bottom-1 left-1 p-1 text-md z-10 text-outline font-black w-[120px] bg-zinc-950 bg-opacity-85 text-center">Now Playing</div>
                                </div>
                            )}
                            {index < playingGameId && <img alt="cleared-img" src="/images/cleared.png" className="absolute top-0 left-0 pointer-events-none"></img>}
                            <img alt={game.name} className="pointer-events-none" src={`${game.header_image}`} />
                        </div>
                    </button>
                ))}
            </div>
                { games[selectedGameId]?.about_the_game &&
                    <div className="info py-4 grid lg:grid-cols-2 gap-4 min-h-[600px]">
                        <div className="bg-zinc-950 bg-opacity-80 p-4 box-shadow-sm">
                            <h1 className={`text-2xl xl:text-3xl mb-5 ${playingGameId === selectedGameId ? "text-yellow-300" : ""}`}>{games[selectedGameId].name || ""}</h1>
                            {playingGameId === selectedGameId && (
                                <>
                                    <span className="text-green-300">Setolo</span> is currently playing <span className="text-purple-300">{games[selectedGameId].name}</span>!
                                    Join me Monday at 8:00PM EST to watch me play the game.
                                    <br />
                                </>
                            )}
                            <div className="my-4" dangerouslySetInnerHTML={{ __html: `They describe this game like this: "${games[selectedGameId]?.short_description}"` }}></div>
                            {games[selectedGameId].genres &&
                                <div className="genres mb-4">
                                    This game is a{(/[AEIOUaeiou]/).test(games[selectedGameId].genres[0].description[0]) ? "n" : ""} {games[selectedGameId].genres.map((genre) => genre.description.toLowerCase()).join(", ")} game.
                                </div>
                            }
                            {selectedGameId !== playingGameId && (
                                <div className="border border-zinc-800 p-4">
                                    <span className="text-purple-300">{games[selectedGameId].name}</span> is <span className="text-yellow-300">{getOrdinalSuffix(selectedGameId+1)}</span> on the list of games to play.
                                    <div className="text-2xl pt-2">{selectedGameId - playingGameId < 0 ? "This game is FINISHED!" : selectedGameId - playingGameId === 1 ? "This game is up next!" : `${selectedGameId - playingGameId} more game${selectedGameId - playingGameId > 1 ? "s" : ""} to go!`}</div>
                                </div>
                            )}
                        </div>
                        <div className="game-description bg-zinc-950 bg-opacity-75 p-4 box-shadow-sm relative">
                            <h1 className={`block text-2xl xl:text-3xl mb-5 md:pt-0 ${games[selectedGameId].steam_appid ? "pt-4" : ""}`}>About This Game</h1>
                            {games[selectedGameId].steam_appid && <div className="visit-store absolute top-4 right-4 uppercase text-sm"><a href={`https://store.steampowered.com/app/${games[selectedGameId].steam_appid}`} rel="noreferrer" target="_blank">Visit store page</a></div>}
                            <div className="max-w-[616px] ml-auto mr-auto bg-zinc-900 p-2">
                                <div dangerouslySetInnerHTML={{ __html: games[selectedGameId].about_the_game || "" }}></div>
                            </div>
                        </div>
                    </div>
                }

        </div>
    )
}